export default {
    news:{
        0: "Type",
        1: "Recharge Voucher",
        2: "Invitation Code",
        3: "Please Enter Invitation Code",
        4: "Front Side",
        5: "Back",
    },
    foot: {
        nav1: "Página",
        nav2: "Mercado",
        nav3: "Transacciones",
        nav4: "Moneda",
        nav5: "Activos",
    },
    userLevel: {
        level0: "Usuarios comunes",
        level1: "Usuarios de plata",
        level2: "Usuarios de oro",
        level3: "Usuarios de platino",
        level4: "Usuarios de diamantes",
        level5: "Usuarios del Rey",
        level6: "Usuarios supremos",
    },
    currenctType: {
        title: "Seleccione por favor uma moeda",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dólar',
        currenctType15: 'Dólares de Hong Kong',
        currenctType16: 'Moeda de Taiwan',
        currenctType17: 'myr',
        currenctType18: 'Nova moeda',
        currenctType19: 'Iene japonês',
        currenctType20: 'Euro',
        currenctType21: 'AUD',
        currenctType22: 'Rupiah Indonésia',
        currenctType23: 'libra',
        currenctType24: 'Baht tailandês',
        currenctType25: 'Cad',
        currenctType26: 'Dong vietnamita',
        currenctType27: 'Won coreano',
        currenctType28: 'Macau Pataca',
    },
    account: {
        login: "Login",
        label_eMail: "Buzón",
        place_eMail: "Por favor, introduzca su dirección de correo electrónico",
        tip_eMail: "Introduzca correctamente su dirección de correo electrónico",
        label_password: "Contraseña",
        place_password: "Introduzca su contraseña",
        tip_password: "Por favor, introduzca la contraseña correcta",
        no_account: "¿Aún no tiene una cuenta?",
        register: "Registrarse",
        send: "Enviar código de verificación",
        label_code: "Código de verificación de correo electrónico",
        place_code: "Código de verificación de correo electrónico",
        tip_code: "Introduzca su código de verificación de correo electrónico",
    },
    assets: {
        title: "Activos",
        total_assets: "Activos totales",
        recharge: "recarga",
        withdraw: "Retirar",
        available: "disponible",
        freeze: "congelado",
        total: "Total",
        label_bank: "Moneda",
        place_bank: "Por favor, elija la moneda",
        tip_bank: "Por favor, elija la moneda",
        label_amount: "Importe",
        place_amount: "Introduzca el importe",
        tip_amount: "Introduzca el importe correcto",
        label_password: "Contraseña",
        place_password: "Introduzca su contraseña",
        tip_password: "Introduzca la contraseña correcta",
        label_balance: "USDT disponibles",
        all: "Todas las retiradas",
        no_password: "Por favor, introdúzcalo para la seguridad de sus fondos",
        withdraw_psd: "Contraseña de retirada",
        label_wallet: "Billetera",
        place_wallet: "Por favor, elija la billetera",
        tip_wallet: "Por favor, elija la billetera",
    },
    currency: {
        position: "Posición",
        drawer_title: "moneda_comercio",
        now: "actual",
        open: "Open",
        volume: "Volumen",
        height: "Máximo",
        low: "Mínimo",
        buy: "comprar largo",
        sell: "venta corta",
        name: "nombre",
        direction: "dirección",
        crm_order: "Confirmar orden",
        now_price: "precio_actual",
        type_time: "Hora de entrega (deslizar a la izquierda para rendimientos más altos)",
        second: "segundos",
        buy_amount: "importe de compra",
        available: "disponible",
        service_fee: "comisión",
        min_number: "Compra al menos",
        errorTip1: "Entrada incorrecta de la cantidad",
        errorTip2: "Saldo insuficiente disponible",
        auto_jump: "Saltar automáticamente el pedido después",
        keep: "Continuar haciendo pedidos",
        revenue: "Ingresos esperados",
    },
    home: {
        quick: "Descarga rápida de monedas",
        support: "Soporte",
        nav1: "Compartir",
        nav2: "Monedero",
        nav3: "Información",
        nav4: "Tutoriales",
        nav5: "Atención al cliente",
        nav6: "Minería",
        nav7: "Sobre nosotros",
        nav8: "Suscripción de nuevas monedas",
        increase: "Lista de subidas",
        volume: "Volumen",
        table_name: "Nombre",
        table_lastPrice: "Último precio",
        table_increase: "Subir/Bajar",
        table_volume: "Volumen",
    },
    lockming: {
        profit: "Ganancias recientes (diarias)",
        cycle: "Ciclo financiero",
        limit: "Límite único",
        dividend_time: "Tiempo de pago de dividendos",
        every_day: "diario",
        funds: "Fondos en custodia",
        back: "Retorno al vencimiento",
        ransom: "Rescate anticipado",
        label_amount: "Importe de la garantía",
        place_amount: "Por favor, introduzca,,,",
        available: "Disponible",
        expect_income: "Ingresos previstos",
        title: "Minería para ingresos",
        tip: "Minado para obtener ingresos",
        label_today_income: "Ingresos estimados hoy",
        label_total_income: "Ingresos acumulados",
        label_order: "Pedidos en custodia",
        label_number: "Importe único",
        daily_return: "Rentabilidad diaria",
        subscribe: "Suscripción",
        position: "Posición",
        label_state: "Estado",
        subscribe_time: "Hora de suscripción",
        expiration_time: "tiempo de expiración",
        income: "ingreso",
        errorTip1: "Por favor, introduzca la cantidad",
        tip_tit: "¿¿ está seguro de que quiere retirarse antes de lo previsto?",
    },
    market: {
        spots: "Disponible en stock",
        contract: "Contratos",
        currency_trans: "Comercio de divisas",
        increase: "Principales ganancias",
        volume: "Facturación",
        label_name: "Nombre",
        label_lastPrice: "Último precio",
        table_increase: "Arriba o abajo",
        table_volume: "Facturación",
    },
    my: {
        details: "Detalles",
        help: "Centro de ayuda",
        information: "Información",
        language: "Cambio de idioma",
        password: {
            account_title: "Cambiar contraseña",
            withdraw_title: "Cambiar contraseña",
            label_oldPsd: "Contraseña antigua",
            place_oldPsd: "Introduzca su antigua contraseña",
            label_newPsd: "Nueva contraseña",
            place_newPsd: "Por favor, introduzca una nueva contraseña",
            label_crmPsd: "Confirmar contraseña",
            place_crmPsd: "Vuelva a introducir una nueva contraseña",
            withdraw_tip: "Si no ha establecido una contraseña de retirada, déjela en blanco",
            password_tips: "The password must be a combination of 6-16 letters and numbers",
        },
        address: {
            title: "Dirección de la cartera",
            account_number: "Número de cuenta",
            add_btn: "Añadir dirección",
            label_type: "Seleccionar tipo",
            place_type: "Seleccione el tipo",
            label_number: "Número de cuenta",
            place_number: "Introduzca el número de cuenta",
            wallet_address: 'Dirección de la billetera',
            bank_name: 'Nombre del Banco',
            bank_address: "Dirección bancaria",
            name: "Nombre",
            payee_address: 'Dirección del beneficiario',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nombre de la sucursal",
            branch_code: "Código de la sucursal",
            bank_code: "Código bancario",
            phone: "Número de teléfono móvil",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Por favor, introduzca el contenido",
        },
        history: {
            title: "Historia",
            recharge_record: "Registro de recarga",
            withdraw_record: "Registro de retiradas",
            withdraw: "Retiradas",
            number: "número",
            currency: "moneda",
            state: "Estado",
            state0: "Pendiente",
            state1: "Completado",
            state2: "Rechazado",
        },
        identity: {
            title: "Autenticación",
            label_country: "Seleccione una nacionalidad",
            place_country: "Seleccione una nacionalidad",
            label_name: "Nombre",
            place_name: "Introduzca su nombre",
            label_id: "Número de identificación",
            place_id: "Por favor, introduzca su número de DNI/licencia de conducir/pasaporte",
        },
        my: {
            list1: "Autenticación",
            list2: "Gestión de pedidos",
            list3: "Historial de fondos",
            list4: "Dirección de la cartera",
            list5: "Cambiar idioma",
            list6: "Invitar amigos",
            list7: "Atención al cliente",
            list8: "Tutoriales",
            list9: "Cambiar contraseña",
            logout: "Cerrar sesión",
        },
        order: {
            title: "Posición",
            direction: "Dirección",
            sell_price: "precio de la orden",
            number: "cantidad",
            type: "Plazo de entrega",
            buy_price: "Precio_oferta",
            profit: "beneficio/pérdida",
            sell_time: "hora_venta",
        },
        share: {
            title: "Invitar a amigos",
            save_ewm: "Guardar código QR",
            invite_link: "Invitar enlace",
            copy_address: "Copiar dirección",
            invite_code: "Código de registro",
            copy_code: "Copiar contenido",
            tip: "Regístrate a través del código QR o del enlace anterior",
        },
    },
    transaction: {
        spots: "spot",
        contract: "contrato",
        currency_trans: "moneda_transacciones",
        exchange: "flash",
        sustainable: "perpetuo",
        warehouse: "posición por posición",
        jump_lever: "apalancamiento de salto",
        sell: "vender",
        buy: "buy",
        available: "disponible",
        check_price: "precio_limite",
        market_price: "precio_mercado",
        number: "cantidad",
        max: "máximo",
        bail: "margen",
        buy_long: "Comprar largo",
        sell_short: "Vender en corto",
        last_price: "Último precio",
        now_entrust: "Confianza_actual",
        now_position: "Posición actual",
        table_amount: "Importe de retirada",
        is_reduce: "Sólo posición reducida",
        shi: "Sí",
        fou: "No",
        position_number: "Número de posiciones mantenidas",
        is_profit: "Beneficio realizado",
        wei: "Todavía no",
        open_price: "Precio de apertura",
        mark_price: "precio de marca",
        flat_price: "precio de nivel fuerte",
        trim_lever: "ajustar apalancamiento",
        flat_store: "Cerrar posición",
        trim_bail: "Ajustar margen",
        reduce_bail: "Reducir margen",
        now_bail: "Margen de posición actual",
        max_add: "Incremento máximo",
        refer_flat: "Precio plano fuerte de referencia ajustado",
        expect_profit: "Beneficio/pérdida esperado",
    },
    common: {
        confirm: "Confirmar",
        all: "Todos",
        close: "Cancelar",
        copy_success: "Copia correcta",
        copy_error: "¡Fallo de copia!",
        place_input: "Por favor, introduzca el contenido",
        more: "Más",
        main: "prin",
        sub: "adju",
        success: "¡Éxito!",
        cancel_tip: "¿Seguro que quiere cancelar?" ,
        notice: "Notificación",
        logout_tip: "¿Confirmar la salida?",
        tip: "Consejos",
        day: "Días"
    },
    params: {
        transType0: "Al contado",
        transType1: "Contrato",
        directionType0: "Comprar",
        directionType1: "Vender",
        lockStateType0: "En funcionamiento",
        lockStateType1: "Ha terminado",
        lockStateType2: "Cancelado",
    },
    city: {
        albania: "Albania",
        algeria: "Argelia",
	    argentina: "Argentina",
	    armenia:  "Armenia",
	    australia: "Australia",
	    pakistan: "Pakistán",
        austria:  "Austria",
	    bahrain: "Bahrein",
	    belgium: "Bélgica",
	    bosnia_and_Herzegovina: "Bosnia y Herzegovina",
	    brazil: "Brasil",
	    brunei: "Brunei",
	    bulgaria: "Bulgaria",
	    cambodia: "Camboya",
		canada: "Canadá",
		cameroon: "Camerún",
		chile: "Chile",
		colombia: "Colombia",
		costa_Rica: "Costa Rica",
		croatia: "Croacia",
		cyprus: "Chipre",
		czech_Republic: "República Checa",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estonia",
		ethiopia: "Etiopía",
        finland : "Finlandia",
		france: "Francia",
		georgia: "Georgia",
		germany: "Alemania",
		ghana: "Ghana",
		greece: "Grecia",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong kong, China",
		hungary: "Hungría",
		iceland: "Islandia",
		ireland: "Irlanda",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Irán",
		iraq: "Irak",
		japan: "Japón",
		kazakstan: "Kazajstán",
		kenya: "Kenia",
		korea: "Corea del Sur",
		kuwait: "Kuwait",
		kyrgyzstan:"Kirguistán",
		laos:"Laos",
		latvia:"Letonia",
		lithuania:"Lituania",
		luxembourg:"Luxemburgo",
		macao_China:"Macao, China",
		macedonia:"Macedonia",
		malaysia:"Malasia",
		malta:"Malta",
		mexico:"México",
		moldova:"Moldavia",
		monaco:"Mónaco",
		mongolia:"Mongolia",
		montenegro:"República de Montenegro",
		morocco:"Marruecos",
		myanmar:"Myanmar",
		netherlands:"Países Bajos",
		new_Zealand:"Nueva Zelanda",
		nepal:"Nepal",
		nigeria:"Nigeria",
		norway:"Noruega",
		oman:"Omán",
		palestine:"Palestina",
		panama:"Panamá",
		paraguay:"Paraguay",
		peru:"Perú",
		philippines:"Filipinas",
		poland:"Polonia",
		portugal:"Portugal",
		puerto_Rico:"Puerto Rico",
		qatar:"Qatar",
		romania:"Rumanía",
		russia:"Rusia",
		republic_of_Trinidad_and_Tobago:"República de Trinidad y Tobago",
		rwanda:"Rwanda",
		saudi_Arabia:"Arabia Saudita",
		serbia:"Serbia",
		singapore:"Singapur",
		slovakia:"Eslovaquia",
		slovenia:"Eslovenia",
		south_Africa:"Sudáfrica",
		spain:"España",
		sri_Lanka:"Sri Lanka",
		sweden:"Suecia",
		switzerland:"Suiza",
		taiwan_China:"Taiwán, China",
		tajikistan:"Tayikistán",
		tanzania:"Tanzania",
		thailand:"Tailandia",
		turkey:"Turquía",
		turkmenistan:"Turkmenistán",
		ukraine:"Ucrania",
		united_Arab_Emirates:"Emiratos Árabes Unidos",
		united_Kingdom:"Reino Unido",
		united_States:"Estados Unidos",
		uzbekistan:"Uzbekistán",
		venezuela:"Venezuela",
		vietnam:"Vietnam",
		afghanistan:"Afganistán",
		angola:"Angola",
		azerbaijan:"Azerbaiyán",
		bangladesh:"Bangladesh",
		belarus:"Bielorrusia",
		belize:"Belice",
		benin:"Benin",
		bhutan:"Bhután",
		bolivia:"Bolivia",
		botswana:"Botswana",
		british_Virgin_Islands:"Islas Vírgenes Británicas",
		burkina_Faso:"Burkina Faso",
		burundi:"Burundi",
		cape_Verde:"Cabo Verde",
		cayman_Islands:"Islas Caimán",
		central_African_Republic:"República Centroafricana",
		chad:"Chad",
		comoros:"Comoras",
		the_Republic_of_the_Congo:"Congo (brazzaville)",
		democratic_Republic_of_the_Congo:"República Democrática del Congo (rdc)",
		djibouti:"Djibouti",
		ecuador:"Ecuador",
		el_Salvador:"El Salvador",
		equatorial_Guinea:"Guinea Ecuatorial",
		eritrea:"Eritrea",
		fiji:"Fiji",
		gabon:"Gabón",
		gambia:"Gambia",
		greenland:"Groenlandia",
		guatemala:"Guatemala",
		guinea:"Guinea",
		haiti:"Haití",
		isle_of_Man:"Isla de Man",
		cote_d_Ivoire:"Costa de Marfil",
		jamaica:"Jamaica",
		jordan:"Jordania",
		lebanon:"Líbano",
		lesotho:"Lesotho",
		liberia:"Liberia",
		libya:"Libia",
		madagascar:"Madagascar",
		malawi:"Malawi",
		maldives:"Maldivas",
		mali:"Malí",
		mauritania:"Mauritania",
		mauritius:"Mauricio",
		mozambique:"Mozambique",
		namibia:"Namibia",
		nicaragua:"Nicaragua",
		republic_of_Niger:"Níger",
		north_Korea:"Corea del Norte",
		reunion:"Reunión",
		san_Marino:"San Marino",
		senegal:"Senegal",
		sierra_Leone:"Sierra Leona",
		somalia:"Somalia",
		sudan:"Sudán",
		suriname:"Surinam",
		eswatini:"Swazilandia",
		syria:"Siria",
		togo:"Togo",
		tonga:"Tonga",
		tunisia:"Túnez",
		united_States_Virgin_Islands:"Islas Vírgenes de los Estados Unidos",
		uganda:"Uganda",
		uruguay:"Uruguay",
		vatican:"Vaticano",
		yemen:"Yemen",
		yugoslavia:"Yugoslavia",
		zambia:"Zambia",
		zimbabwe:"Zimbabwe",
		china:"China",
	}
};
